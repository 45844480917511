*{box-sizing: border-box; font-family: 'ABC Normal';}
html,body {height: 100%;}
html,body,#root {font-size: 10px; background: none;}
html{overflow: hidden;}
#root {display: flex;position: absolute; bottom: 0px; right: 0px;}
body.ReactModal__Body--open{overflow:hidden;}
:focus {outline: none;}

.chat { display: flex; overflow: hidden; position: relative; min-height: 6rem; align-items: flex-end;}
.chat-closed{ display: flex; align-items: center; cursor:pointer; position: relative; bottom: 0rem; right: 0rem; background: #007AFF;	border: 0.2rem solid #FFFFFF;	border-radius: 5rem; height: 5rem;}
.chat-closed .content{ display: flex; align-items: center; justify-content: flex-start; padding: 0rem 1.6rem; }
.chat-closed .content .logo{ color: #ffffff; margin-right: 0.9rem;}
.chat-closed .content .text{ color: #ffffff; font-family: 'ABC Normal'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 140%;}
.chat-closed .status{ position: absolute; bottom: 0rem; right: -0.2rem; border: 2px solid #FFFFFF; border-radius: 50%; z-index: 100; width: 1.4rem; height: 1.4rem;}
.chat-closed .status.online{ background: #7AC942; }
.chat-closed .status.offline,
.chat-closed .status.paused{ background: #FF0000; }
.chat-closed .unread-messages-count{position: absolute; top: -0.8rem; right: -0.2rem; padding: 0rem 0.6rem; display: flex; align-items: center; justify-content: center; border: 1px solid #FFFFFF; border-radius: 8px; z-index: 100; background: #FF0000; color: #ffffff; font-family: 'ABC Normal'; font-style: normal; font-weight: 500; font-size: 12px; line-height: 140%;}

.chat-opened{ display: flex; flex-direction: column; width: 42rem; height: 56rem; padding: 1rem;}
.chat-opened .content{ display: flex; position: relative; flex-grow: 1; flex-direction: column; background: #FFFFFF;	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);	border-radius: 8px;}
.chat-opened .header{display: flex; flex-direction: row; align-items: center; background: #007AFF;border-top-left-radius: 8px; border-top-right-radius: 8px; flex-shrink: 0; padding: 1.6rem; }
.chat-opened .content .operator-list{display: flex; align-items: center; margin-left: 1rem; margin-right: 1.6rem;}
.chat-opened .content .operator-list .avatar {width: 3.4rem; height: 3.4rem; margin-left: -1rem; box-shadow:inset 0px 0px 0px 0.2rem #ffffff; padding: 0.2rem; margin-right: 0rem;}
.chat-opened .content .operator-list .avatar .avatar-content {width: 3rem; height: 3rem;}
.chat-opened .content .operator-list .avatar.plus {background-color: #007AFF;}
.chat-opened .content .operator-list .avatar.plus .avatar-content {display: flex; flex-direction: row; align-items: center; justify-content: center; font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 14px; line-height: 17px; color: #FFFFFF;}
.chat-opened .header .text-block{display: flex; flex-direction: column; flex-grow: 1; margin-right: 1.6rem; white-space: nowrap; overflow:hidden;}
.chat-opened .header .text-block .main-title{font-family: 'ABC Normal Medium'; color: #ffffff; font-style: normal; font-weight: 500; font-size: 16px;line-height: 140%; margin-bottom: 0.3rem; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.chat-opened .header .text-block .subtitle{font-family: 'ABC Normal'; color: #ffffff; font-style: normal;font-weight: 500;font-size: 12px;line-height: 140%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
.chat-opened .header .text-block .subtitle .status{display: inline-block; margin-right: 0.4rem;}
.chat-opened .header .menu{display: flex; align-items: center; flex-direction: row;}
.chat-opened .header .menu .icon{color: #ffffff; font-size: 1.6rem; cursor: pointer; margin-left: 0.8rem;}
.chat-opened .header .menu .icon:first-child{margin-left: 0rem;}
.chat-opened .header .menu .dropdown .dropdown-content{left: -24rem; right: -2rem;}
.chat-opened .header .menu .dropdown .dropdown-content .powered-by{background: #F5F5F5;border-radius: 4px;padding: 2.4rem; text-decoration: none; text-align: center; margin-top: 2.4rem;}
.chat-opened .header .menu .dropdown .dropdown-content .powered-by .description{font-family: 'ABC Normal Medium';font-style: normal;font-weight: 500;font-size: 12px;line-height: 20px; color:#808080; text-decoration: none; margin-bottom: 0.8rem; display: block;}
.chat-opened .header .minimize{ color: #FFFFFF; margin-left: auto; cursor: pointer;}
.chat-opened .message-list {flex-grow: 1; min-height: 0; flex-direction: column; display: flex; flex: 1 1 1px; position: relative;}
.chat-opened .message-list-content {position: relative; display: flex; overflow-y: scroll; min-height: 0; padding: 0rem 1.6rem; flex-grow: 1; flex-direction: column;}
.chat-opened .message-list-content .message-system-info{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 16px; color: #808080; margin: 2rem 0rem;}
.chat-opened .message-list-content .messages-date-separator{position: relative; margin: 2.4rem 0rem; text-align: center;padding: 1rem 0rem;}
.chat-opened .message-list-content .messages-date-separator::before{content: ""; height: 0px; position: absolute; top: 2rem; left: 0rem; right: 0rem; border-bottom: 1px solid #F5F5F5; z-index: 0;}
.chat-opened .message-list-content .messages-date-separator .separator-date{position: relative; display: inline-block; background-color: #ffffff;font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 20px; text-align: center; color: #808080; z-index: 2; padding: 0rem 1rem;}

.chat-opened .message-list .typing-list{display: flex; flex-direction: row; align-items: center; padding: 1rem 1.6rem;}
.chat-opened .message-list .typing-list .typing-icon{color: #808080; margin-right: 0.8rem; font-size: 1.8rem;}
.chat-opened .message-list .typing-list .typing-text{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 140%;color: #808080;}
.chat-opened .message-list .typing-list .avatar{margin-right: 0.8rem;}

.message-item{display: flex; flex-direction: row; align-items: flex-start; margin: 1.2rem 0rem;}
.message-item .message-avatar{margin-right: 0.8rem}
.message-item .message-content{display: flex; flex-direction: column; align-items: flex-start;min-height: 9.4rem;}
.message-item .message-content .message-header{display: flex; flex-direction: row; align-items: center; margin-bottom: 0.8rem;}
.message-item .message-content .message-header .name{font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 1.6rem; line-height: 1.9rem; margin-right: 1rem;}
.message-item .message-content .message-header .date-time{font-family: 'ABC Normal'; font-style: normal; font-weight: 600; font-size: 1.2rem; line-height: 2rem; color: #A8A8A8; cursor: default;}
.message-item .message-content .message{white-space: pre-line; padding: 0.8rem;background: #F5F5F5; border-radius: 0px 8px 8px 8px;font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 1.6rem; line-height: 140%; color: #000000; max-width: 27.3rem;}
.message-item .message-content .read-by{display: flex; align-items: center; position: relative;font-family: 'ABC Normal'; font-style: normal; font-weight: 600; font-size: 1.2rem; line-height: 2rem; color: #A8A8A8; cursor: default; cursor: pointer;}
.message-item .message-content .read-by .read-by-icon{margin-left: 0.6rem; color:#007AFF;}
.message-item .message-content .read-by .read-by-content{display:flex; flex-direction: column; position: absolute; bottom:2rem; left: 0rem; background-color: #ffffff; border-radius: 5px; box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);padding: 1rem;}
.message-item .message-content .read-by .read-by-content .item{display: flex; flex-direction: row;}
.message-item .message-content .read-by .read-by-content .item .name{white-space: nowrap;min-width: 20rem;}
.message-item .message-content .read-by .read-by-content .item .date-time{white-space: nowrap;}

.message-item .message-content .rating{display: flex; flex-wrap: wrap; justify-content: flex-start; margin-top: 0.8rem; cursor: pointer;}
.message-item .message-content .rating .rating-item{display: flex; align-items: center; background: #F5F5F5; border-radius: 4px; padding: 8px; margin-right: 0.8rem; margin-bottom: 0.8rem;}
.message-item .message-content .rating .rating-item:hover{background-color: #E5E5E5;}
.message-item .message-content .rating .rating-item.no-hover:hover{background-color: #F5F5F5; cursor: default;}
.message-item .message-content .rating .rating-item .icon{font-size: 2rem; margin-right: 0.8rem;}
.message-item .message-content .rating .rating-item .text{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 140%;}

.message-item .message-content .message .files{display: flex; flex-direction: row; align-items: center; flex-wrap: wrap; margin-bottom: 1rem; justify-content: center;}
.message-item .message-content .message .files .file-item{height: 10rem; margin-left: 1rem; margin-bottom: 1rem; text-decoration: none;}
.message-item .message-content .message .files .file-item:first-child{margin-left: 0rem;}
.message-item .message-content .message .files .file-item img{height: 100%; width: auto;}
.message-item .message-content .message .files .file-item .icon{display: flex; flex-direction: row; align-items: center; justify-content: center; border: 1px solid; border-radius: 8px; height: 100%; width: auto; padding: 1rem; font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 1.4rem; line-height: 130%; border-color: #7c7c7c; color: #7c7c7c; background-color: rgb(124 124 124 / 30%);}
.message-item .message-content .message .files .file-item .xls,
.message-item .message-content .message .files .file-item .xlsx{border-color: #007c00; color: #007c00; background-color: rgb(0 124 0 / 30%);}
.message-item .message-content .message .files .file-item .pdf{border-color: #d21418; color: #d21418; background-color: rgb(210 20 24 / 30%);}
.message-item .message-content .message .files .file-item .doc,
.message-item .message-content .message .files .file-item .docx{border-color: #2a639a; color: #2a639a; background-color: rgb(42 99 154 / 30%);}

.message-item.grouped{margin-bottom: 0.8rem; margin-top: 0rem;}
.message-item.grouped .message-avatar{display: none;}
.message-item.grouped .message-content{margin-left: 4rem; min-height: auto;}
.message-item.grouped .message-content .message-header{display: none;}

.message-item.grouped.grouped-first{margin-top: 1.2rem;}
.message-item.grouped.grouped-first .message-avatar{display: block;}
.message-item.grouped.grouped-first .message-content{margin-left: 0rem;}
.message-item.grouped.grouped-first .message-content .message-header{display: flex;}

.message-item.grouped.grouped-last{margin-bottom: 1.2rem;}

.message-item.sent{flex-direction: row-reverse;}
.message-item.sent .message-avatar{margin-right: 0rem; margin-left: 0.8rem;}
.message-item.sent .message-content{display: flex; flex-direction: column; align-items: flex-end;}
.message-item.sent .message-content .message-header{flex-direction: row-reverse; justify-content: flex-end;}
.message-item.sent .message-content .message-header .name{margin-right: 0rem; margin-left: 1rem;}
.message-item.sent .message-content .message{border-radius: 8px 0px 8px 8px; max-width: auto;}
.message-item.sent .message-content .read-by .read-by-content{left: auto; right: 0rem;}

.message-item.received .message-content .message{background-color: #D9EBFF; }

.message-item.sent.grouped .message-content{margin-left: 0rem;}
.message-item.sent.grouped .message-content .read-by{display: none;}
.message-item.sent.grouped.grouped-last .message-content .read-by{display: flex;}

.chat-opened .respond-panel{display:flex;background: #FFFFFF; box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); border-radius: 4px; margin: 0rem 1.6rem 1.6rem; user-select: none;}
.chat-opened .respond-panel .text-block{display: flex; flex-direction: column;width: 100%; user-select: none; padding: 1.6rem 0rem;}
.chat-opened .respond-panel .text-block .textarea-content{display: flex; flex-direction: column;width: 100%; user-select: none; padding: 0rem 1.6rem;}
.chat-opened .respond-panel .text-block textarea{border: none; resize: none; outline:none; flex-grow: 1; font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 1.6rem; line-height: 2.8rem; user-select: auto;}
.chat-opened .respond-panel .text-block textarea::placeholder {color: #A8A8A8;}
.chat-opened .respond-panel .text-block .controls{display: flex; flex-direction: row; align-items: center; position: relative;}
.chat-opened .respond-panel .text-block .controls .control{position:relative; display: flex; flex-direction: row; align-items: center; color: #808080; margin-right: 1.6rem; cursor: pointer;}
.chat-opened .respond-panel .text-block .controls .control:hover{color: #000000;}
.chat-opened .respond-panel .text-block .controls .control .icon{height: 2rem; margin-right: 0.8rem; font-size: 2.2rem; text-decoration: none;}
.chat-opened .respond-panel .text-block .controls .control .text{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 1.6rem; line-height: 2.8rem;  text-decoration-line: underline;}
.chat-opened .respond-panel .text-block .controls .control .emoteicon-control{display: flex; flex-direction: row; align-items: center;}
.chat-opened .respond-panel .text-block .controls .control .emote-icon-picker{position: absolute; bottom: 3rem; left: 0rem;}
.chat-opened .respond-panel .text-block .controls .btn{margin-left: auto;}


.chat-opened .introduction-form{display:flex;background: #FFFFFF; box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); border-radius: 4px; margin: 0rem 1.6rem 1.6rem; user-select: none; padding: 3.2rem 1.6rem 1.6rem 1.6rem; z-index: 10;}
.chat-opened .introduction-form > .form{width: 100%;}
.chat-opened .introduction-form > .form .form-group{margin-bottom: 1.6rem;}
.chat-opened .introduction-form > .form .send-block{display: flex; flex-direction: row;}
.chat-opened .introduction-form > .form .send-block .gdpr{display: flex; flex-grow: 1; align-items: center;}
.chat-opened .introduction-form > .form .send-block .gdpr .gdpr-confirmation{display: flex; align-items: center; cursor: pointer;}
.chat-opened .introduction-form > .form .send-block .gdpr .gdpr-confirmation.invalid .checkbox {border-color: #FF0000;}
.chat-opened .introduction-form > .form .send-block .gdpr .label{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 140%; margin-left: 0.8rem;}
.chat-opened .introduction-form > .form .send-block .gdpr .link{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 140%; color: #000000;}
.chat-opened .introduction-form > .form .send-block .btn{padding-left: 1.6rem; padding-right: 1.6rem; margin-left: auto;}


.chat-opened .content .dropzone{position: absolute; display: none; top: 4rem; bottom: 0rem; left: 0rem; right: 0rem; z-index: 100; flex-direction: column; align-items: center; justify-content: center; padding: 30px; border-width: 2px; border-radius: 2px; border-color: #007AFF; border-style: dashed; background-color: rgb(250, 250, 250, 0.9); color: rgb(189, 189, 189);  outline: none;  transition: border 0.24s ease-in-out 0s; }
.chat-opened .content .dropzone.visible{display: flex;}
.chat-opened .content .dropzone .drop-files-here{font-family: 'ABC Normal Medium'; text-align: center; font-style: normal; font-weight: 500; font-size: 3.4rem; line-height: 130%; margin-bottom: 1.6rem; color: #007AFF;}



/************** Mobilní verze ***********/

.chat.mobile{min-height: 7rem;}
.chat.mobile .chat-opened .content .operator-list{display: none;}
.chat.mobile .chat-closed {margin-bottom: 1rem; margin-right: 1rem;}
.chat.mobile .chat-opened .respond-panel .text-block .controls .control .icon{margin-right: 0rem;}
.chat.mobile .chat-opened .respond-panel .text-block .controls .control .text{display: none;}


/************** Status a priorita ***********/

.status{width:0.8rem; height: 0.8rem; border-radius: 10px; }
.status.border{width:1.2rem; height: 1.2rem; border: 3px solid; }
.status.white-border{border-color: #ffffff; }
.status.light-blue-border{border-color: #ECF5FF; }
.status.grey-border{border-color: #F5F5F5; }

.status.online{background-color:#7AC942 ; }
.status.offline{background-color:#FF0000 ; }
.status.paused{background-color:#FF0000 ; }

/************** Modal okno ******************/
.ReactModal__Overlay{overflow-y: auto;z-index: 100; background-color:rgba(245, 245, 245, 0.75) !important; }
.modal{min-width: 38rem;}
.mobile-body .modal{min-width: auto;}
.modal.center{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -60%); }
.mobile-body .modal.center{ position: absolute; top: 50%; left: 2.4rem; right: 2.4rem; transform: translate(0%, -60%); }
.modal .modal-content{padding: 3.2rem 4rem;}
.modal .modal-content .modal-footer .link{ font-size: 16px; line-height: 28px;}
.modal .modal-content .modal-footer .btn.blue{ padding-left: 4rem; padding-right: 4rem;}


/************** obecné styly ******************/
.ml-auto{margin-left: auto;}
.mr-auto{margin-right: auto;}

/* Notifikace */
.rnc__notification-container--top-center {top: 42px !important;}
.rnc__notification-message {white-space: pre-line !important;}

@font-face {
	font-family: 'ABC Normal';
	src: url('../Fonts/ABC/ABCNormal-Bold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
	
@font-face {
	font-family: 'ABC Normal';
	src: url('../Fonts/ABC/ABCNormal-Heavy.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
	
@font-face {
	font-family: 'ABC Normal Medium';
	src: url('../Fonts/ABC/ABCNormal-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
	
	
@font-face {
	font-family: 'ABC Normal Neutral';
	src: url('../Fonts/ABC/ABCNormal-Neutral.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
	
	
@font-face {
	font-family: 'ABC Normal Normal';
	src: url('../Fonts/ABC/ABCNormal-Normal.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
	
@font-face {
	font-family: 'ABC Normal';
	src: url('../Fonts/ABC/ABCNormal-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.hp-1{
	height: 1px !important;
}
.hp-2{
	height: 2px !important;
}
.hp-3{
	height: 3px !important;
}
.hp-4{
	height: 4px !important;
}
.hp-5{
	height: 5px !important;
}
.hp-6{
	height: 6px !important;
}
.hp-7{
	height: 7px !important;
}
.hp-8{
	height: 8px !important;
}
.hp-9{
	height: 9px !important;
}
.hp-10{
	height: 10px !important;
}
.hp-11{
	height: 11px !important;
}
.hp-12{
	height: 12px !important;
}
.hp-13{
	height: 13px !important;
}
.hp-14{
	height: 14px !important;
}
.hp-15{
	height: 15px !important;
}
.hp-16{
	height: 16px !important;
}
.hp-17{
	height: 17px !important;
}
.hp-18{
	height: 18px !important;
}
.hp-19{
	height: 19px !important;
}
.hp-20{
	height: 20px !important;
}
.hp-21{
	height: 21px !important;
}
.hp-22{
	height: 22px !important;
}
.hp-23{
	height: 23px !important;
}
.hp-24{
	height: 24px !important;
}
.hp-25{
	height: 25px !important;
}
.hp-26{
	height: 26px !important;
}
.hp-27{
	height: 27px !important;
}
.hp-28{
	height: 28px !important;
}
.hp-29{
	height: 29px !important;
}
.hp-30{
	height: 30px !important;
}
.hp-31{
	height: 31px !important;
}
.hp-32{
	height: 32px !important;
}
.hp-33{
	height: 33px !important;
}
.hp-34{
	height: 34px !important;
}
.hp-35{
	height: 35px !important;
}
.hp-36{
	height: 36px !important;
}
.hp-37{
	height: 37px !important;
}
.hp-38{
	height: 38px !important;
}
.hp-39{
	height: 39px !important;
}
.hp-40{
	height: 40px !important;
}
.hp-41{
	height: 41px !important;
}
.hp-42{
	height: 42px !important;
}
.hp-43{
	height: 43px !important;
}
.hp-44{
	height: 44px !important;
}
.hp-45{
	height: 45px !important;
}
.hp-46{
	height: 46px !important;
}
.hp-47{
	height: 47px !important;
}
.hp-48{
	height: 48px !important;
}
.hp-49{
	height: 49px !important;
}
.hp-50{
	height: 50px !important;
}

.fs1{
	font-size: 1px !important;
}
.fs2{
	font-size: 2px !important;
}
.fs3{
	font-size: 3px !important;
}
.fs4{
	font-size: 4px !important;
}
.fs5{
	font-size: 5px !important;
}
.fs6{
	font-size: 6px !important;
}
.fs7{
	font-size: 7px !important;
}
.fs8{
	font-size: 8px !important;
}
.fs9{
	font-size: 9px !important;
}
.fs10{
	font-size: 10px !important;
}
.fs11{
	font-size: 11px !important;
}
.fs12{
	font-size: 12px !important;
}
.fs13{
	font-size: 13px !important;
}
.fs14{
	font-size: 14px !important;
}
.fs15{
	font-size: 15px !important;
}
.fs16{
	font-size: 16px !important;
}
.fs17{
	font-size: 17px !important;
}
.fs18{
	font-size: 18px !important;
}
.fs19{
	font-size: 19px !important;
}
.fs20{
	font-size: 20px !important;
}
.fs21{
	font-size: 21px !important;
}
.fs22{
	font-size: 22px !important;
}
.fs23{
	font-size: 23px !important;
}
.fs24{
	font-size: 24px !important;
}
.fs25{
	font-size: 25px !important;
}
.fs26{
	font-size: 26px !important;
}
.fs27{
	font-size: 27px !important;
}
.fs28{
	font-size: 28px !important;
}
.fs29{
	font-size: 29px !important;
}
.fs30{
	font-size: 30px !important;
}
.fs35{
	font-size: 35px !important;
}
.fs40{
	font-size: 40px !important;
}
.fs45{
	font-size: 45px !important;
}
.fs50{
	font-size: 50px !important;
}

