.menu{display: flex;  flex-direction: row;  align-items: flex-start;}
.menu .menu-item{font-family: 'ABC Normal Medium';  font-style: normal;  color:#000000;  font-weight: 500; margin: 0px 16px;  font-size: 1.6rem;  line-height: 1.9rem;  text-align: center;}
.menu .menu-item.selected{color:#007AFF;}

.tab-menu{display: flex; flex-direction: row; align-items: center; box-shadow: 0px 1px 0px #E5E5E5;}
.tab-menu .tab-item{ display: flex; position: relative; flex-direction: row; flex-grow: 1; cursor: pointer; align-items: center; justify-content: center; padding-left: 1.6rem; padding-right: 1.6rem; margin-left: 0.7rem; margin-right: 0.7rem;user-select: none;}
.tab-menu .tab-item:first-child{ margin-left: 0rem;}
.tab-menu .tab-item:last-child{ margin-right: 0rem;}
.tab-menu .tab-item .title{font-family: 'ABC Normal Medium'; font-size: 16px; line-height: 40px; text-align: center; color: #000000;}
.tab-menu .tab-item.selected{box-shadow: 0px 1px 0px #007AFF;}
.tab-menu .tab-item.selected .title{color: #007AFF;}

.dropdown{ position: relative; display: flex; user-select: none; flex-direction: row;justify-content: start;align-items: center; cursor: pointer;}

.dropdown .text{ font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px;}
.dropdown .text.blue{ color: #007AFF;}
.dropdown > .icon{ margin-left: 0.8rem; font-size: 14px;}
.dropdown .addon{ display: flex; flex-direction: row; align-items: center;}
.dropdown .addon .separator{ width: 1px; height: 20px; background: #E5E5E5; margin: 0rem 0.8rem;}
.dropdown .addon .icon{ color:#000000 ; font-size: 1.4rem;}

.dropdown .circle{ width: 3rem; height: 3rem; border-radius: 100px;display: flex; align-items: center; justify-content: center; margin-right: 0.8rem;}
.dropdown .circle.blue{ background-color: #007AFF ;}
.dropdown .circle.online{ background-color: #7AC942 ;}
.dropdown .circle > .icon{font-size: 2.4rem; color:#ffffff;}
.dropdown .circle.offline{background-color: #FF0000 ;}
.dropdown .circle.paused{background-color: #FF9900 ;}
.dropdown .circle .text{color:#ffffff; font-family: 'ABC Normal Medium';  font-style: normal;  font-weight: 500;  font-size: 1.4rem;  line-height: 1.7rem;  text-align: center;}
.dropdown .dropdown-content {display:none; min-width: 100%; flex-direction: column; position: absolute; z-index: 100; top:4rem; left:0rem; background-color: #ffffff; border-radius: 5px;box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); padding: 1.6rem; border-radius: 8px; cursor: default;}

.dropdown .dropdown-content .arrow {width: 1rem; height: 1rem; transform: rotate(45deg); position: absolute;  z-index: 100; background-color: #ffffff;top: -0.5rem;}
.dropdown .dropdown-content.show{display:flex; }
.dropdown .dropdown-content .dropdown-item {display:flex; flex-direction: row; align-items: center; padding: 0.6rem 1.6rem; font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; color: #007AFF; cursor: pointer; border-radius: 0.5rem;}
.dropdown .dropdown-content .dropdown-item a{ font-family: inherit; font-style: inherit; font-weight: inherit; font-size: inherit; line-height: inherit; color:inherit;}
.dropdown .dropdown-content .dropdown-item:hover {background-color: #F5F5F5; }
.dropdown .dropdown-content .dropdown-separator{height: 1px; background: #E5E5E5;  margin: 1.6rem 0rem;}

.dropdown.small .text{  font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 1.4rem; line-height: 3rem;}

.dropdown.filled{ background: #F5F5F5;border-radius: 4px; min-height: 38px; padding: 0.4rem 0.8rem;}
.dropdown.filled:hover{ background: #E5E5E5;}

.dropdown.filled.rounded{ border-radius: 38px; }
.dropdown.filled > .text{flex-grow: 1; text-align: left; white-space: nowrap;}
.dropdown.filled.small{  border-radius: 4px; min-height: 32px; padding: 0rem 1.6rem;}
.dropdown.filled.rounded.small{ border-radius: 16px; }

.filled-block{padding: 0rem 0.8rem; position: relative; display: flex; user-select: none; flex-direction: row; justify-content: start;align-items: center; background-color: #F5F5F5; padding: 0rem 0.8rem; border-radius: 0.8rem;}
.filled-block.grey{  background-color: #F5F5F5;}
.filled-block .text{  margin: 0rem 0.8rem; font-family: 'ABC Normal'; color: #000000;  font-style: normal;  font-weight: 500;  font-size: 1.6rem;  line-height: 1.9rem;  text-align: center; margin-right: 0.8rem;}
.filled-block.small{  border-radius: 0.4rem;}
.filled-block.small .text{  font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 1.4rem; line-height: 3rem;}

.avatar{border-radius: 100px; width: 3rem; height: 3rem; position: relative;}
.avatar .status{position: absolute; bottom: -0.3rem; right: -0.2rem; z-index: 100;}
.avatar .avatar-content{border-radius: 100px; width: inherit; height: inherit; position: relative; overflow: hidden;}
.avatar .avatar-content img{width: 100%; height: 100%;}
.avatar .avatar-content .colored-avatar{display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;}
.avatar .avatar-content .colored-avatar .text{color: #fff;font-family: 'ABC Normal'; font-style: normal; font-weight: 500; font-size: 1.4rem; line-height: 1.7rem;}

.avatar.small{width: 2rem; height: 2rem; overflow: hidden;}
.avatar.small .avatar-content .colored-avatar .text{font-weight: 500; font-size: 1rem; line-height: 1.3rem;}

.form-group{display: flex; flex-direction: column;}
.form-group label{ display: flex; font-family: 'ABC Normal Neutral'; font-size: 16px; line-height: 16px; color: #808080;margin-bottom: 0.6rem;}
.form-group label .description{font-family: 'ABC Normal'; font-style: normal; font-weight: 450; font-size: 12px; line-height: 16px; margin-left: 1rem;}
.form-group label .description.right{margin-left: auto;}
.form-group .info{font-family: 'ABC Normal Neutral'; font-size: 12px; line-height: 16px; color: #808080;margin-top: 0.6rem;}

.input-group{position:relative; display: flex; flex-direction: row; min-height: 3.8rem;}
.input-group .addon{position: absolute; top:0rem; bottom: 0rem; z-index: 10; display: flex; align-items: center; justify-content: center;}
.input-group .addon.left{ left: 0.8rem;}
.input-group .addon.right{ right: 0.8rem;}
.input-group input,.input-group select, .input-group textarea{position: absolute; top:0px; right:0px; bottom:0px; left: 0px; border: 1px solid #F5F5F5; border-radius: 0.4rem; background: #F5F5F5; outline:none; height: inherit; box-sizing: border-box; padding: 0.6rem 1.6rem; font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 140%; color: #080808;}
.input-group input.invalid,.input-group select.invalid, .input-group textarea.invalid{border-color: #FF0000;}
.input-group textarea{position: relative; width: 100%;}
.input-group input:focus, .input-group input:focus-visible, .input-group select:focus, .input-group select:focus-visible{background: #FFFFFF; border: 1px solid #808080;}
.input-group.has-addon-left input, .input-group.has-addon-left select{padding-left: 4rem;}
.input-group.has-addon-right input, .input-group.has-addon-right select{padding-right: 4rem;}

.btn{border-radius: 20px;font-family: 'ABC Normal'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 30px; border:none; padding: 0.5rem 1.6rem; cursor: pointer;}
.btn.blue{background: #007AFF;color:#ffffff;}
.btn:disabled, .btn[disabled], .btn.disabled{background-color: #ddd;}

.checkbox-block{display: flex; flex-direction: row; align-items: flex-start; cursor: pointer;}
.checkbox-block .checkbox{display: flex; flex-shrink: 0; flex-direction: row; align-items: center; justify-content: center; user-select: none; width: 2rem; height: 2rem; background: #F5F5F5; border: 1px solid #A8A8A8; box-sizing: border-box; border-radius: 0.2rem; margin-top: 0.2rem;}
.checkbox-block.disabled .checkbox{background: #E5E5E5; border: 1px solid #A8A8A8;}
.checkbox-block .checkbox.checked{background: #007AFF; border: 1px solid #007AFF}
.checkbox-block.disabled .checkbox.checked{background: #A8A8A8; border: 1px solid #A8A8A8;}
.checkbox-block .checkbox .icon{width: 1rem; color:#ffffff;}
.checkbox-block .label{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 140%; caret-color: transparent; margin-left: 1rem;}
.checkbox-block .label.line-through{text-decoration-line: line-through;}

.emote-icon-picker{display: flex; cursor: default; flex-direction: row; width: 23.8rem; flex-wrap: wrap; overflow-y: auto; height: 30rem; background-color: #ffffff; border-radius: 5px; box-shadow: 0px 0px 20px rgb(0 0 0 / 10%); padding: 1rem;}
.emote-icon-picker .emote-icon{display: flex; font-size: 2rem; margin: 0.3rem; cursor: pointer;}

.modal{margin: 0rem auto;max-width: 50rem;padding: 2rem 0rem;outline: none; }
.modal .modal-content{position:relative;display: flex; flex-direction: column;  padding: 4rem; background: #FFFFFF; box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15); border-radius: 8px;}
.modal .modal-content .modal-header{display: flex; position: relative;}
.modal .modal-content .modal-header .modal-title{font-family: 'ABC Normal Medium'; font-style: normal; font-weight: 500; font-size: 24px; line-height: 130%; margin: 0rem 0rem 1.6rem 0rem;}
.modal .modal-content .close{position: absolute;top: 0.6rem; right: 0.6rem; display: flex; background: none; border: none; padding: 0rem; color:#000000; padding: 1rem; cursor: pointer; z-index: 10;}
.modal .modal-content .close .icon{font-size: 1.6rem;}
.modal .modal-content .modal-body { font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 16px; line-height: 140%; color:#000000;}
.modal .modal-content .modal-footer {display: flex; flex-direction: row; align-items: center; margin-top: 4rem;}
.modal .modal-content .modal-footer.center {justify-content: center;}

.link{font-family: 'ABC Normal'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 170%; text-decoration-line: underline; color: #007AFF; cursor: pointer;}
